export default {
  "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© ", _interpolate(_named("year")), ", Débuter mes impôts. All rights reserved."])},
  "gb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "saveSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save successful"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])},
  "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["french"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english"])},
  "menuClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close menu"])},
  "menuOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open le menu"])},
  "form": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required"])}
  },
  "table": {
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data to display"])},
    "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records per page"])},
    "paginationSeparator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" of "])}
  },
  "validation": {
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])}
  },
  "phone": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension"])},
    "isPublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in pre-tax form"])},
    "types": {
      "CellPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell phone"])},
      "Work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
      "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "Fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
      "EmergencyLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency line"])},
      "TollFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toll-free"])},
      "Pager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pager"])},
      "HearingImpaired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hearing impaired"])}
    }
  },
  "address": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
    "addressLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 1"])},
    "addressLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 2"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
    "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "isPublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in pre-tax form"])}
  },
  "provinces": {
    "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontario"])},
    "QC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quebec"])}
  },
  "messages": {
    "markAllAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all messages as read"])}
  },
  "faultyQuestions": {
    "topMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some mandatory questions are unanswered."])},
    "deleteThisMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this message"])}
  },
  "preTaxFormLayout": {
    "taxYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxation year:"])},
    "taxProvince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxation province:"])},
    "leftMenu": {
      "familyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family information"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
      "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children"])},
      "preTaxForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-tax form"])},
      "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])}
    },
    "subscriptionHasExpired": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription has expired"])},
      "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<i>", _interpolate(_named("orgName")), "</i>'s subscription has not been renewed for the current year. It is therefore impossible to access this page."])}
    },
    "saveAndGoToPrevious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and go back"])},
    "saveAndLogOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and quit"])},
    "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quit"])},
    "saveAndGoToNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and continue"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "removePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This person is no longer my partner / I no longer wish to complete this person's form."])},
    "useSameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use same email address for both partners"])},
    "useSameAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use same address for both partners"])},
    "basedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on tax information available as of"])}
  },
  "preTaxFormSectionPage": {
    "mandatoryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicates a mandatory field"])}
  },
  "preTaxFormLoginPage": {
    "returningUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access the pre-tax form"])},
      "moreText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide an email address to continue. We'll send you a one-time authentication code."])},
      "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send me a code"])}
    },
    "helpLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help? Download our quick start guide."])},
    "messages": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured. Please try again later."])}
    }
  },
  "preTaxFormIntroPage": {
    "fiscalYearSelect": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxation year"])},
      "questionPart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For which year do you want to fill out your pre-tax form? The current year is "])},
      "questionPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", but it is possible to fill out the form for a previous year."])}
    },
    "provinceSelect": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province of residence"])},
      "questionPart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What was your province of residence on"])},
      "questionPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December 31st"])},
      "refuseButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse and quit"])},
      "acceptButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept and continue"])}
    },
    "optInMessage": {
      "part1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By completing this form, I consent to the information provided being used by my accountant and/or their employees in the preparation of my tax return for the year ", _interpolate(_named("year")), "."])},
      "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This information could be, in whole or in part, transmitted to the tax authorities if necessary."])},
      "part3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As part of the filing of the tax return, it could be used in third-party applications, such as tax processing software, and hosted on the servers of these software. For more information regarding the use of your data, please consult your accountant."])}
    }
  },
  "preTaxFormUserPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family information"])},
    "walkInClientEmailDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-person clients do not need to provide their email address."])},
    "sectionTitles": {
      "personalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
      "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
      "residentialAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential address"])},
      "mailingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing address"])},
      "mailingAddressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(if different than residential address)"])}
    },
    "partnerPreQuestions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
      "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will we be preparing your partner's tax declaration?"])},
      "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you obtained permission from your partner to share their personal information?"])},
      "noPermissionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you answer no, your spouse will have to fill out their own form by going to this address:"])}
    }
  },
  "preTaxFormChildrenPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family information - Children"])},
    "dependentChildren": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependent children"])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have dependent children?"])}
    },
    "addChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a child"])}
  },
  "childInfoInput": {
    "newChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New child"])},
    "dependentOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependent of"])},
    "bothPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both partners"])},
    "removeMeAsParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This child is no longer in my care / I no longer wish to complete this child's form. This will not affect the other parent's account."])},
    "removeChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove this child"])}
  },
  "preTaxFormSecureSpaceSelectPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convoflo secure space"])},
    "form": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Several secure spaces found"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Several secure spaces* between you and your accountant have been found. Please choose one to continue."])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which secure space do you want to use?"])},
      "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can check if this is the correct secure space by clicking on the following link:"])},
      "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and continue"])}
    },
    "partnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure spaces are provided by our partner"])}
  },
  "preTaxFormEndPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document checklist"])},
    "comeBack": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to your pre-tax form"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can access your pre-tax form and the list of documents to be provided at any time. Please take note of the below address to easily come back here:"])}
    },
    "yourSharedFolder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your shared folder"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now access your Convoflo client area (formerly TagMyDoc), a secured shared space between you and your accountant. It is a simple and secure way to transfer your documents."])},
      "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to submit your documents"])}
    },
    "quitText": {
      "withUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To safely quit, click the button below.. You will be redirected to ", _interpolate(_named("organizationName")), "'s website."])},
      "withoutUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To safely quit, click the button below."])}
    }
  },
  "preTaxFormAsProPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-tax form"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged in as a professional. To access your organization's pre-tax form, you need to log out first."])},
    "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out and go to pre-tax form"])}
  },
  "officeLayout": {
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "leftMenu": {
      "myOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My organization"])},
      "organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizations"])},
      "inPersonClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-person clients"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
      "filledForms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "adminStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
      "plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans"])},
      "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
      "preTaxFormCustomization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-tax form"])},
      "progressCustomization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
      "convofloConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convoflo"])},
      "mondayConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monday.com"])}
    },
    "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help? Email us:"])},
    "supportEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support", "@", "debutermesimpots.ca"])}
  },
  "tmgStatus": {
    "connectedOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organization is correctly connected to Convoflo using the following account:"])},
    "notConnectedOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organization is not connected to Convoflo. Click to correct the situation."])},
    "connectedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your user account is correctly connected to Convoflo using the following account:"])},
    "notConnectedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your user account is not connected to Convoflo. Click to correct the situation."])},
    "form": {
      "headerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you already have a <i>Convoflo</i> account, use this form to link it to your <i>Débuter mes impôts</i> account."])},
      "emailInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Convoflo email address"])},
      "passwordInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Convoflo password"])},
      "cancelButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "submitButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link accounts"])}
    }
  },
  "organization": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization name"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign / change logo"])},
    "monday": {
      "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key"])},
      "boardId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["board_id"])},
      "groupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group_id"])},
      "userFullNameColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client: full name column name"])},
      "partnerFullNameColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner: full name column name"])},
      "emailColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email column name"])},
      "taxYearColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxation year column name"])},
      "answersPdfColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers PDF column name"])},
      "docListPdfColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document list PDF column name"])}
    }
  },
  "user": {
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "isInPersonClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-person client"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "preferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "birthDateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yyyy/mm/dd"])},
    "notificationPreferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email notification preferences"])},
      "noPreferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notification preferences have been set"])},
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New pre-tax form is completed"])}
    },
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "mailingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing address"])},
    "status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "waitingEmailConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for email confirmation"])},
      "activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account activated"])}
    },
    "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role(s)"])}
  },
  "genders": {
    "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "roles": {
    "SuperAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super administrator"])},
    "OrgAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation administrator"])},
    "Pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
    "TaxPayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax payer"])}
  },
  "usersDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
  },
  "userView": {
    "accountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User account information"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
    "notificationPreferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification preferences"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "noContactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contact information has been provided"])},
    "partnerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner information"])},
    "noPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No partner on file"])},
    "openPartnerView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open partner file"])},
    "doesBusinessWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does business with"])},
    "forceConvofloReconnect": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force Convoflo reconnection"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go!"])}
    }
  },
  "userForm": {
    "title": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("edit"), " a user"])},
    "personalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
    "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an address"])},
    "phoneNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone numbers"])},
    "addPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a phone number"])},
    "dangerZone": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danger zone"])},
      "archiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive this user"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write <b>archive</b> in the box below to continue."])},
      "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])}
    }
  },
  "organizationsDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizations"])}
  },
  "organizationForm": {
    "title": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an organization"])},
      "editMy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my organization"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit an organization"])}
    },
    "dontCreateTmdAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't create a Convoflo account"])},
    "logoInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The logo is presented on a background of the main color of your pre-tax form, as seen by your clients."])},
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
    "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an address"])},
    "phoneNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone numbers"])},
    "addPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a phone number"])},
    "orgDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization detail"])},
    "convofloClientsFolder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convoflo \"clients\" folder"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use any folder in your Convoflo file tree as a \"clients\" folder. To choose a folder, open Convoflo and navigate to the desired folder. In the address bar of your browser, copy the part of the address following <i>https://app.convoflo.com/vf/</i>, for example: <b>12abCD/1wxYz</b >."])},
      "inputHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convoflo clients folder URL"])}
    },
    "monday": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monday.com integration"])},
      "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key"])},
      "emailColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email column name"])},
      "taxYearColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxation year column name"])},
      "answersPdfColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers PDF column name"])},
      "docListPdfColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document list PDF column name"])}
    },
    "preTaxForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-tax form customization"])},
      "primaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary color"])},
      "secondaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary color"])},
      "formUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form address"])},
      "formUrlExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value determines your organization's pre-tax form address"])},
      "formUrlTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum length is 3"])},
      "formUrlNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This address is not available"])},
      "returnUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return address"])},
      "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction text"])},
      "outroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing text"])}
    },
    "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "administratorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding more administrators will be available once the organization has been created."])}
  },
  "organizationTable": {
    "createButtonLabel": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("create"), " an organization"])},
    "administrators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrators"])},
    "hasTmdAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TMD account"])},
    "expiresAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration"])},
    "tmdPlan": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TMD plan"])},
      "api": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
      "tmd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TMD"])}
    },
    "nbPros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro count"])},
    "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])}
  },
  "organizationView": {
    "addAdminButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an administrator"])},
    "addProButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a professional"])},
    "subscription": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No active subscription"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription expired on"])},
      "expiresOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires on"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a subscription"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit subscription"])}
    },
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No address has been provided"])},
    "phoneNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone numbers"])},
    "noPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No phone number has been provided"])},
    "noLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No logo has been provided"])},
    "administrators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrators"])},
    "professionals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionals"])}
  },
  "OrganizationStaffView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])}
  },
  "OrganizationPreTaxFormView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-tax form customization"])},
    "colorsAndUrls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colors and addresses"])},
    "primaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary color"])},
    "secondaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary color"])},
    "preTaxFormUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-tax form address"])},
    "returnUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return address"])},
    "introTexts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction texts"])},
    "introTextFr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "introTextEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "outroTexts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing texts"])},
    "outroTextFr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "outroTextEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "OrganizationProgressStatesView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form progress customization"])},
    "progressStates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress states"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These states are used to track the progress of pre-tax forms in your dashboard. Edit them to better fit your workflow."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important: The original state value is used when the box is left empty."])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No progress status has been configured. An administrator of your organization can do this by clicking the edit button at the bottom of this page."])},
      "originalValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original value"])}
    }
  },
  "organizationConvofloView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convoflo integration"])},
    "waitingForCvfApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the Convoflo service..."])},
    "status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection to Convoflo"])},
      "accountType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account type:"])}
    },
    "clientsFolder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients folder"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This folder is the root for all your DMI clients in Convoflo. DMI uses this to find or create secure spaces for your clients, old and new."])},
      "notSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No folder is configured. An administrator of your organization can do this by clicking the edit button at the bottom of this page."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder name"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convoflo ID"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convoflo URL"])},
      "openInConvoflo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in Convoflo"])}
    },
    "permissions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case of problems (access denied in Convoflo, missing folders, etc.), you can reset your organization's permissions. All users (administrators and professionals) whose Convoflo account is connected to their DMI account will be given the necessary permissions to access your customer files."])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation may take a few minutes. Please be patient."])},
      "taxYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For what taxation year?"])},
      "startButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
    }
  },
  "organizationMondayConfigView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monday.com integration"])},
    "apiKey": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key"])},
      "notSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No API key is configured. An administrator of your organization can do this by clicking the edit button at the bottom of this page."])}
    },
    "formProps": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monday.com form properties"])}
    }
  },
  "AddUserToOrganizationForm": {
    "title": {
      "OrgAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an administrator"])},
      "Pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a professional"])}
    }
  },
  "OrganizationSubscriptionForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])}
  },
  "InPersonClientsDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-person clients"])}
  },
  "InPersonClientForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a client"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An in-person client does not require an email address or password. This allows you to fill out the form on their behalf or lend them a device so they can do it from your office."])},
    "convofloWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Convoflo account will be created for this client."])},
    "createButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save client"])},
    "createAndStartButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save & begin pre-tax form"])}
  },
  "InPersonClientTable": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-person clients"])},
    "columns": {
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth date"])}
    },
    "openFormTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the pre-tax form as "])}
  },
  "plan": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "maxFilledForms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forms"])},
    "maxStorageGb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])}
  },
  "subscription": {
    "purchasedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase date"])},
    "expiresAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date"])},
    "pricePaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price paid (before taxes)"])}
  },
  "plansDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans"])}
  },
  "planTable": {
    "createButtonLabel": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("create"), " a plan"])}
  },
  "planForm": {
    "title": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a plan"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a plan"])}
    },
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan details"])},
    "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All fields are required."])},
    "beforeTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["before taxes"])},
    "minusOneForUnlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set to -1 for unlimited"])}
  },
  "planView": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan detail"])}
  },
  "filledForm": {
    "status": {
      "Incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete"])},
      "Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])}
    }
  },
  "filledFormsDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "yearFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
    "statusFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "assignedToFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to"])},
    "progressFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])}
  },
  "filledFormTable": {
    "columns": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage of documents marked as sent by the client"])},
      "assignedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to"])},
      "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])}
    },
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign to a staff member"])}
  },
  "progress": {
    "Assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned"])},
    "OnHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for client"])},
    "Ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])},
    "ToBeReviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be reviewed"])},
    "Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
    "Sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])}
  },
  "filledFormView": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pre-tax form ", _interpolate(_named("taxYear")), ": "])},
    "downloadAsPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download in PDF format"])},
    "tabs": {
      "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client file"])},
      "forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
      "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children"])},
      "docChecklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents to be provided checklist"])},
      "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposited files"])}
    },
    "actions": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive this form"])},
      "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow the client to modify their form"])},
      "orgAdminsOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (only organization administrators can perform this action)"])}
    },
    "formHeader": {
      "fiscalYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxation year"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])}
    },
    "filledFormHeader": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
      "firstCompletedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed on"])}
    },
    "userInfo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client file"])},
      "clientNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client number"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
      "personalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
      "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
      "notes": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These notes are visible to all your team members, but not to your clients."])}
      },
      "archiveDialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow the client to modify their form"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upon confirmation, this form will be archived and will no longer be visible in your dashboard. In case of an error, you will need to contact technical support to reactivate it."])}
      },
      "unlockDialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow the client to modify their form"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upon confirmation, the status of the form will change from <b>Completed</b> to <b>Incomplete</b> and the client will be able to edit their pre-tax form again. This also applies to their partner, if applicable."])}
      }
    },
    "files": {
      "noTmdOrgAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your organization is not connected to Convoflo. This feature is therefore unavailable."])},
      "noTmdUserAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your user account is not connected to Convoflo. This feature is therefore unavailable to you."])},
      "formIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This form is incomplete. A client may only upload documents once the form has been completed."])},
      "noTaxFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user doesn't have a Convoflo shared folder."])},
      "createTmdShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create shared folder"])},
      "resendShareText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the user has not received an invitation to their Convoflo secure space, you can send it again by clicking on the button below."])},
      "resendShareButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Convoflo invitation"])},
      "invitationSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation sent successfully."])}
    },
    "emptySection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No answer to display for this section"])},
    "noChildrenOnFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No children on file"])}
  },
  "filledFormDocumentLists": {
    "formIsIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document checklist will be available once the form is completed."])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the complete list of documents to be provided by this client."])},
    "extraInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At any time, a client can access his form, go to the final section, and check documents to indicate he has sent them to you."])},
    "taxPayerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once each document has been sent (as per your accountant's instructions), please mark it in the following list. Changes are saved automatically."])}
  },
  "filledFormDocumentList": {
    "noDocumentRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No documents required"])}
  },
  "filledFormFiles": {
    "clientUploadedFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client documents"])},
    "walkInClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is not document management for in-person clients."])},
    "organizationUploadedFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income tax return"])},
    "openFolderInConvoflo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open folder in Convoflo"])}
  },
  "tmdFileTable": {
    "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download all"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to send a file"])},
    "notifyTaxPayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify client"])},
    "notifyTaxPayerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an email to notify the client about new documents available in Convoflo."])}
  },
  "tmdFile": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "mimeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified"])},
    "lastDownloadBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaded by"])},
    "lastDownloadAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaded on"])},
    "uploadedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded by"])},
    "uploadedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded on"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
  },
  "adminStatsDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "intro": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filled forms"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This table shows the number of filled forms received by each organization for the last three tax years. From a billing point of view, spouse forms only count as one (1). The information is presented as follows: completed / total received."])}
    }
  },
  "error": {
    "fileTooLarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This file is too large. It exceeds the maximum of 20 MB."])}
  },
  "rules": {
    "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This date is invalid. Expected format: yyyy/mm/dd."])},
    "emailAlreadyInUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email address is not available."])}
  },
  "validations": {
    "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value must be a decimal"])},
    "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value must be an integer"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value is required"])}
  },
  "notifications": {
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notification"])},
    "type": {
      "newDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New documents available"])}
    },
    "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as read"])}
  }
}