import {IAddressDto} from '@/types/dtos/contact/IAddressDto'
import {IPhoneNumberDto} from '@/types/dtos/contact/IPhoneNumberDto'
import {IEntityWithTmdAccount} from '@/types/dtos/IEntityWithTmdAccount'
import {EmptyTmdAccountDto} from '@/types/dtos/ITmdAccountDto'
import {INotificationPreferenceDto} from '@/types/dtos/notification/INotificationPreferenceDto'

export interface IUserDto extends IEntityWithTmdAccount {
  userName: string
  email: string
  firstName: string
  lastName: string
  /** Example: Julia Smith */
  fullName: string
  /** Example: Smith, Julia */
  fullNameListing: string
  gender: string
  birthDate: number | undefined
  emailConfirmed: boolean
  preferredLanguage: string
  notificationPreferences: INotificationPreferenceDto[]
  roles: string[]
  addresses: IAddressDto[]
  mailingAddress: string
  phoneNumbers: IPhoneNumberDto[]
  organizationId: string
  kioskOrganizationId: string | undefined | null
  partnerId: string | undefined
  partner: IUserDto | undefined
  createdAt: number | undefined
}

export const EmptyUser: IUserDto = {
  id: '00000000-0000-0000-0000-000000000000',
  userName: '',
  email: '',
  firstName: '',
  lastName: '',
  addresses: [],
  mailingAddress: '',
  phoneNumbers: [],
  roles: [],
  gender: '',
  birthDate: undefined,
  emailConfirmed: false,
  preferredLanguage: 'fr',
  notificationPreferences: [],
  fullName: '',
  fullNameListing: '',
  organizationId: '00000000-0000-0000-0000-000000000000',
  kioskOrganizationId: undefined,
  partnerId: undefined,
  tmdAccount: EmptyTmdAccountDto,
  partner: undefined,
  createdAt: undefined
}
