import {EntityStateBase} from '@/store/types/EntityStateBase'
import {IUserDto} from '@/types/dtos/IUserDto'
import {Message} from '@/types/Message'

export class InPersonClientsState extends EntityStateBase<IUserDto> {

  constructor(messages: Message[]) {
    super(messages, 'in-person-clients')
  }

  postFetch(): Promise<boolean> {
    return Promise.resolve(false);
  }
}